.home-page {
    --home-page-horizontal-padding: 7rem;

    background: var(--colorBackground);
    @include mobile-and-tablette {
        --home-page-horizontal-padding: 1rem;
    }
    > section {
        padding: 1.5rem var(--home-page-horizontal-padding);
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;
        max-width: 100vw;
        @include mobile-and-tablette {
            padding: 1rem var(--home-page-horizontal-padding);
        }
    }
    .cta-section {
        display: flex;
        justify-content: center;
        gap: 4rem;
        padding: 1rem 0;
        @include mobile-and-tablette {
            flex-direction: column;
            width: 100%;
            gap: 2rem;
            button {
                width: 100%;
                height: 4rem;
            }
        }
    }
    .hero-section {
        > &__titles {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            width: 100%;
            h1 {
                @extend %title1;
                font-size: 5rem;
                color: var(--colorPrimary700);
                @supports (background-clip: text) {
                    background-image: $colorGradient1;
                    background-clip: text;
                    color: transparent;
                }
                @include mobile-and-tablette {
                    font-size: 2.5rem;
                }
            }
            h2 {
                @extend %title2;
                font-size: 2rem;
                @include mobile-and-tablette {
                    font-size: 1.2rem;
                }
            }
        }
    }
    .projects-section {
        gap: 4rem;
        padding: 4rem var(--home-page-horizontal-padding);
        overflow: hidden;
        @include mobile-and-tablette {
            gap: 3rem;
        }
        > h2 {
            @extend %title3;
            font-size: 2rem;
            text-align: center;
        }
        .project-card-container {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            &:nth-child(odd) {
                justify-content: flex-end;
            }
            &:nth-child(even) {
                .project-card {
                    flex-direction: row-reverse;

                    aside {
                        border-radius: 0 $borderRadius1 $borderRadius1 0;
                    }

                    main {
                        .project-card__background {
                            border-radius: $borderRadius1 0 0 $borderRadius1;
                        }
                    }
                }
            }
        }
    }
}
