/**/

// ******Breakpoints******

$mobile: 570;
$tablette: 960;
$desktop: 1460;
$tv: 'taille XL';
$breakpoints: (
    mobile: $mobile,
    tablette: $tablette,
    desktop: $desktop,
);

// ******Design colors******

$colorAccent1: #0d1820;
$colorAccent1Transparent: rgba(13, 24, 32, 0.8);
$colorAccent2: #b7bec4;
$colorAccent2Gradient: linear-gradient(
    180deg,
    rgba(183, 190, 196, 0.1) 0%,
    rgba(13, 24, 32, 0.3) 100%
);
$colorAccent3: #dde7ec;
$colorBg1: #ffffff;
$colorBg2: #eaeef0;
$colorBg3: #ffffff;
$colorBg4: #f0ece9;
$colorBg5: rgba(42, 52, 59, 0.9);
$colorBg6: rgba(13, 24, 32, 0.9);
$colorBg7: #0d1820;
$colorBg8: #152633;
$colorTxt1: #000000;
// $colorGradient1: linear-gradient(
//     138.82deg,
//     #152633 23.33%,
//     #2d536e 72.86%,
//     #0d1820 126.28%
// );
// $colorGradient2: linear-gradient(
//     96.37deg,
//     #ba7b5f 11.25%,
//     rgba(186, 123, 95, 0.989583) 11.26%,
//     #c78365 54.97%,
//     #7a503e 100.03%
// );
// $colorGradient3: linear-gradient(
//     122.09deg,
//     #121433 2.48%,
//     #142038 60.08%,
//     #0d1820 122.2%
// );
$colorBtn1: #a16a52;

// ******V2 Design colors******

// Light mode

$colorPrimary500: #344a53;
$colorPrimary600: #00141c;
$colorPrimary700: #000914;

$colorSecondary: #efc8cb;

$colorTertiary: #f2faff;

$colorAccent: #775562;

$colorBackground: #eaeef0;

// Dark mode

$colorPrimary500DarkMode: #4c2e91;
$colorPrimary600DarkMode: #1e0261;
$colorPrimary700DarkMode: #0d0a10;

$colorSecondaryDarkMode: #efc8cb;

$colorTertiaryDarkMode: #161226;

$colorAccentDarkMode: #775562;

$colorBackgroundDarkMode: #121212;

// Gradients

// $colorBackgroundDarkMode: linear-gradient(
//     70deg,
//     rgba(18, 18, 18, 1) 0%,
//     rgba(31, 24, 43, 1) 100%
// );

$colorGradient1: linear-gradient(
    87.59deg,
    #00141c 1.2%,
    #243242 31.81%,
    #4d5269 62.42%,
    #7f728f 93.03%
);
$colorGradient2: linear-gradient(
    305.32deg,
    #ffc185 4.79%,
    #ffdb74 49.4%,
    #f9f871 94%
);

// base colors

$white: white;
$black: black;

// ******Shadows******

$shadow1: 0px 4px 16px rgba(22, 22, 22, 0.1);
$shadow2: 0px 4px 4px rgba(22, 22, 22, 0.1);
$shadow3: 0px 4px 4px rgba(22, 22, 22, 0.05);
$shadow4: 4px 8px 16px rgba(22, 22, 22, 0.2);

$shadow-retro-interface: 3px 2px 0.5px 0px var(--retro-interface-shadow-color);

// *****Border radius*****

$borderRadius1: 0.3rem;
$borderRadius2: 0.5rem;
