.coming-soon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: $colorBg2;
    height: 100vh;

    @include from-desktop {
        padding: 0 5rem;
        gap: 5rem;

        h1 {
            @include title1;
            font-size: 5rem;
        }
        h2 {
            @include title2;
            font-size: 2rem;
        }
        .cta-section {
            display: flex;
            justify-content: center;
            gap: 4rem;
        }
    }
    @include mobile-and-tablette {
        padding: 0 2rem;
        gap: 3rem;

        h1 {
            @include title1;
            font-size: 2rem;
        }
        h2 {
            @include title2;
            font-size: 1rem;
        }
        .cta-section {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            gap: 2rem;
            a,
            button {
                width: 100%;
            }
        }
    }
}
