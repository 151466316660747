/**/
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

%font__content {
    font-family: 'Poppins', sans-serif;
}

%font__retro {
    font-family: 'Source Code Pro', sans-serif;
}

%extraLight {
    font-weight: 200;
}
%light {
    font-weight: 300;
}
%regular {
    font-weight: 400;
}
%medium {
    font-weight: 500;
}
%semiBold {
    font-weight: 600;
}
%bold {
    font-weight: 700;
}
%extraBold {
    font-weight: 800;
}
%black {
    font-weight: 900;
}
@mixin title1 {
    font-family: 'Source Code Pro', monospace;
    font-weight: 500;
}

%title1 {
    @include title1;
}

@mixin title2 {
    font-family: 'Source Code Pro', monospace;
    font-weight: 300;
}

%title2 {
    @include title2;
}
%title3 {
    @extend %font__content;
    @extend %regular;
    @include mobile-and-tablette {
        font-size: 1.2rem;
    }
}
%title4 {
    @extend %font__content;
    @extend %medium;
    @include mobile-and-tablette {
        font-size: 1.1rem;
    }
}
%title5 {
    @extend %font__content;
    @extend %regular;
    @include mobile-and-tablette {
        font-size: 1rem;
    }
}
%title5Tags {
    @extend %font__content;
    @extend %semiBold;
    font-size: 0.8rem;
}
%title6 {
    @extend %font__content;
    @extend %light;
}
%paragraph1 {
    @extend %font__content;
    @extend %light;
    font-size: 1.2rem;
    @include mobile-and-tablette {
        font-size: 0.8rem;
    }
}
%paragraph2 {
    @extend %font__content;
    @extend %extraLight;
}
%menuTexts {
    @extend %font__content;
    @extend %regular;
}

%retro-interface__title {
    @extend %font__retro;
    @extend %light;
    font-size: 0.9rem;
}

%retro-interface__content {
    @extend %font__retro;
    @extend %regular;
    font-size: 1.1rem;
}
