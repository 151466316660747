/**/

@keyframes loader {
    to {
        transform: rotate(360deg);
    }
}

.loader {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    &:before {
        content: '';
        box-sizing: border-box;
        position: absolute;
        top: calc(50% - 2.5rem);
        left: calc(50% - 2.5rem);
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        border: 4px solid transparent;
        border-top-color: $colorPrimary500;
        border-bottom-color: $colorPrimary500;
        animation: loader 0.8s ease infinite;
    }
    &.full-page {
        height: 100vh;
    }
}
