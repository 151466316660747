/**/

// ***** Informations card *****

// Resetting default behaviors

.infos-card__menu-tab:not(.active) {
    transition: all ease-out 200ms;

    &::before {
        filter: opacity(0);
        transition: all ease-out 200ms;
    }
    &:hover {
        &::before {
            filter: opacity(0.3);
        }
    }
}

.infos-card__menu-tab.active {
    &::before {
        filter: opacity(1);
    }
}

.infos-card__content {
    display: none;
    * {
        filter: opacity(0);
        transition: all ease-out 200ms;
    }
    transition: all ease-out 200ms;
}

// Setting animations behaviors

// Tabs

.infos-card__menu-tab.tab-is-active-appear {
    &::before {
        filter: opacity(0);
    }
}

.infos-card__menu-tab.tab-is-active-enter {
    &::before {
        filter: opacity(0.2);
    }
}

.infos-card__menu-tab.tab-is-active-enter-active {
    &::before {
        filter: opacity(0.5);
    }
}

.infos-card__menu-tab.tab-is-active-enter-done {
    &::before {
        filter: opacity(1);
    }
}

.infos-card__menu-tab.tab-is-active-exit {
    &::before {
        filter: opacity(1);
    }
}

.infos-card__menu-tab.tab-is-active-exit-active {
    &::before {
        filter: opacity(0.5);
    }
}

.infos-card__menu-tab.tab-is-active-exit-done {
    &::before {
        filter: opacity(0);
    }
}

// Contents

.infos-card__content.content-is-active-enter {
    display: auto;

    * {
        filter: opacity(0);
    }
}

.infos-card__content.content-is-active-enter-done {
    display: auto;

    * {
        filter: opacity(1);
    }
}

.infos-card__content.content-is-active-exit {
    display: auto;

    * {
        filter: opacity(1);
    }
}

.infos-card__content.content-is-active-exit-active,
.infos-card__content.content-is-active-exit-done {
    display: none;

    * {
        filter: opacity(0);
    }
}

// Moving preview animation

@keyframes shaking-left {
    0% {
        transform: translatey(0px);
    }
    50% {
        transform: translatey(-6px) translateX(-4px);
    }
    100% {
        transform: translatey(0px);
    }
}

@keyframes shaking-right {
    0% {
        transform: translatey(0px);
    }
    50% {
        transform: translatey(6px) translateX(4px);
    }
    100% {
        transform: translatey(0px);
    }
}

// ***** Typewriter effect *****

@keyframes blinking-cursor {
    0% {
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
    60% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.typewrited-text {
    width: 100%;
    height: auto;
    white-space: break-spaces;
    &__cursor {
        color: var(--colorSecondary);
        position: static;
        animation: blinking-cursor 1000ms infinite;
        margin: 0;
        padding: 0;
        &.L {
            width: 5px;
        }
        &.M {
            width: 3px;
        }
        &.S {
            width: 1px;
        }
        &.off {
            display: none;
        }
    }
}

// ***** Projects cards *****

@keyframes appear-from-left {
    0% {
        opacity: 0;
        translate: -5% 0;
    }
    100% {
        opacity: 1;
        translate: 0;
    }
}

@keyframes appear-from-right {
    0% {
        opacity: 0;
        translate: 5% 0;
    }
    100% {
        opacity: 1;
        translate: 0;
    }
}

// ***** Color transition *****

@keyframes color-transition {
    0% {
        transform: translateY(2000px);
    }
    100% {
        transform: translateY(-2000px);
    }
}

// ***** Buttons *****

@keyframes underline {
    0%,
    10% {
        width: 0%;
    }
    40%,
    60% {
        width: 100%;
        left: 0;
        right: 0;
    }
    90%,
    100% {
        width: 0%;
        right: 0;
        left: 100%;
    }
}
